import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../../components/Header";
import EventList from "../../../components/EventList";
import NearMiss from "../../../components/NearMissList"
import { hydrate, setParams } from "../redux_actions";
// import "./channelDashboard.css";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props.hydrate(props.auth.user.uid);
    console.log(props.auth.user.uid);
  }

  render() {
    console.log(this.props.app.nearMisses);
    return (<div className="pg-cntr">
      <Header hindi={this.props.app.hindi} toggleEvent={this.props.setParams} />
      <div className="pg-cntr-no-header">
        <div style={{fontWeight:"bold", textTransform:"capitalize"}}>My Reports</div>
        <div style={{display:"flex", flexDirection:"column", marginBottom:"2rem", flexGrow:1}}>
        {/* {Object.keys(this.props.app.reports).map(locationWise=>{
          let reports = this.props.app.reports[locationWise];
          if(reports){return <EventList history={this.props.history} key={locationWise} reports={reports} />}
          return null;
        })} */}
        {Object.keys(this.props.app.signals).length
          ?<EventList type='signal' history={this.props.history} reports={this.props.app.signals||{}} />
          :<div style={{marginTop:"2rem"}}>No Reports as of now!</div>}
        {/* {Object.keys(this.props.app.all_nearMissesReported).map(locationWise=>{
          let reports = this.props.app.all_nearMissesReported[locationWise];
          if(reports){return <NearMiss history={this.props.history} key={locationWise} reports={reports} />}
          return null;
        })} */}
        {Object.keys(this.props.app.nearMisses).length
          ?<EventList type='nearMiss' history={this.props.history} reports={this.props.app.nearMisses||{}} />
          :null}
        </div>
      </div>
    </div>)
  }
}

const mapStateToProps = state => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ hydrate, setParams }, dispatch);
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Dashboard);