import firebase from "firebase/app";
import 'firebase/database';
export const hydrate = (userId) => {
  // userId = "dlGMpjH3nHRtrNIOhQS4jfD2XQ53";
  return dispatch => {
    firebase.database().ref("locations").once("value", (snap)=>{
      dispatch({type: "ALL_LOCATIONS", payload: snap.val()});
    });

    firebase.database().ref("signals").once("value", (snap)=>{
      dispatch({type: "ALL_SIGNALS", payload: snap.val()});
    });

    firebase.database().ref("vehicleEvents").once("value", (snap)=>{
      dispatch({type: "ALL_EVENTS", payload: snap.val()});
    });

    firebase.database().ref("settings").once("value", (snap)=>{
      dispatch({type: "ALL_SETTINGS", payload: snap.val()});
    });

    firebase.database().ref("proposedAction").once("value", (snap)=>{
      dispatch({type: "ALL_ACTIONS", payload: snap.val()});
    });

    firebase.database().ref("nearMiss").once("value", (snap)=>{
      dispatch({type: "ALL_NEARMISS", payload: snap.val()});
    });

    // firebase.database().ref(`user/${userId}/responsibleFor`)
    // .once("value", (snap)=>{
    //   let locations = snap.val();
    //   Object.keys(locations).forEach(locationId=>{
    //     firebase.database().ref("reports").orderByChild("location").equalTo(locationId)
    //     .on("value", (snap)=>{
    //       dispatch({type: "UPDATE_REPORTS", payload: {
    //         location: locationId,
    //         reports: snap.val()
    //       }});
    //     });
    //     firebase.database().ref("nearMissesReported").orderByChild("location").equalTo(locationId)
    //     .on("value", (snap)=>{
    //       dispatch({type: "UPDATE_NEARMISSREPORTS", payload: {
    //         location: locationId,
    //         reports: snap.val()
    //       }});
    //     });
    //   });
    // });

    firebase.database().ref(`user/${userId}/signalsReportedToMe`)
    .on("value", snap=>{
      dispatch({type: "RESET_SIGNALREPORTS"});
      let allPosts = snap.val()||{};
      Object.keys(allPosts).forEach((pId)=>{
        let post = allPosts[pId];
        firebase.database().ref(`reports/${post.reportId}`)
        .once("value", (snapshot)=>{
          dispatch({type: "UPDATE_SIGNALREPORTS", payload: {
            reportId: post.reportId,
            report: snapshot.val()
          }});
        })
      });
    });

    firebase.database().ref(`user/${userId}/nearMissesReportedToMe`)
    .on("value", snap=>{
      dispatch({type: "RESET_NEARMISSREPORTS"});
      let allPosts = snap.val()||{};
      Object.keys(allPosts).forEach((pId)=>{
        let post = allPosts[pId];
        firebase.database().ref(`nearMissesReported/${post.reportId}`)
        .once("value", (snapshot)=>{
          dispatch({type: "UPDATE_NEARMISSREPORTS", payload: {
            reportId: post.reportId,
            report: snapshot.val()
          }});
        })
      });
    });
  };
};

export const fetchReportData = (reportId, callback)=>{
  return firebase.database().ref(`reports/${reportId}`)
  .on("value", callback)
}

export const fetchNearMissReportData = (reportId, callback)=>{
  return firebase.database().ref(`nearMissesReported/${reportId}`)
  .on("value", callback)
}

export const fetchUserData = (userId)=>{
  return firebase.database().ref(`user/${userId}/displayName`)
  .once("value")
}

export const updateComment = (reportId, comment)=>{  
  let updates={}
  updates[`reports/${reportId}/comment/`] = comment||null;
  firebase.database().ref().update(updates);
}

export const updateEnSafeId = (reportId, comment)=>{
  let updates={}
  updates[`nearMissesReported/${reportId}/enSafeId/`] = comment||null;
  firebase.database().ref().update(updates);
}

export const updateAction = (reportId, actionType, uid, comment )=>{
  return dispatch=>{
    let updates = {};
    let time = Date.now();
    let newPostRef = firebase.database().ref(`reports/${reportId}/allActions`).push();
    let actionKey = newPostRef.key;
    
    updates[`reports/${reportId}/actions/${actionKey}/type`] = actionType;
    updates[`reports/${reportId}/actions/${actionKey}/time`] = time;
    updates[`reports/${reportId}/actions/${actionKey}/comment`] = comment||null;
    updates[`reports/${reportId}/actions/${actionKey}/takenUpBy`] = uid||null;
    updates[`reports/${reportId}/lastAction/`] = actionKey;
    updates[`reports/${reportId}/takenUpBy`] = uid||null;
    firebase.database().ref().update(updates);
  }
};

export const setParams = ({target, value}) => {
  return dispatch => {
    dispatch({ type: `SET_${target.toUpperCase()}`, payload: value })
  };
}