import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

import { updateComment, updateAction } from '../redux_actions';

class OnIt extends React.Component {
  constructor(props) {
    super(props);
    this.state = { step: 0, addComment: false, comment: props.report.actions && props.report.actions[props.report.lastAction] ? props.report.actions[props.report.lastAction].comment : '' };
    this.handleCommentChange = () => event => {
      this.setState({ ...this.state, "comment": event.target.value });
    };
    this.handleSubmitComment = (el) => {
      // updateComment(this.props.match.params.reportId, this.state.comment);
      this.setState({ ...this.state, addComment: false })
    }
  }
  render() {
    let uid = this.props.auth.user.uid;
    let reportId = this.props.match.params.reportId;
    let report = this.props.report;
    let genericActions = this.props.app.all_settings.genericActions;
    console.log(genericActions);
    let action = report["lastAction"] ? report.actions[report["lastAction"]] : null;
    let proposedActions = this.props.app.all_signals[report.signal].proposedActions||{};
    let type = 0;
    if (action) {
      switch (action.type) {
        case 'a-002':
          type = 1;
          break;
        case 'a-003':
          type = 2;
          break;
        default:
          type = 0;
          break;
      }
    }

    if(Object.keys(report).length){
      console.log(type);
      return <div id="onIt">
      <Stepper activeStep={type} orientation="vertical">
        <Step key='a-002'>
          <StepLabel>{type===1?"You chose to act on this":"Choose to Act"}</StepLabel>
          <StepContent>
            <div style={{marginBottom: "1rem", fontSize: "0.9rem", textAlign:"center", color:"#999"}}>Possible actions you can take</div>
            <Carousel showThumbs={false} showStatus={false}>
              {
              // Object.keys(proposedActions).map(actionId => {
              //   return <div key={actionId} className="action-slide">
              //     <Paper className="slide-card">
              //       <div className="slide-content">
              //         <div className="action-title">{this.props.app.all_actions[actionId].title || ""}</div>
              //         <div className="action-description">{this.props.app.all_actions[actionId].description || ""}</div>
              //       </div>
              //     </Paper>
              //   </div>
              // })
              Object.keys(genericActions).map(actionId => {
                return <div key={actionId} className="action-slide">
                  <Paper className="slide-card">
                    <div className="slide-content">
                      <div className="action-title">{genericActions[actionId].title || ""}</div>
                      <div className="action-description">{genericActions[actionId].description || ""}</div>
                    </div>
                  </Paper>
                </div>
              })
              }
            </Carousel>
            <div className="btn-cntr">
              <Button variant="contained" color="primary" onClick={() => { this.props.updateAction(reportId, 'a-002', uid) }}>Act</Button>
            </div>
          </StepContent>
        </Step>

        <Step key='a-003'>
          <StepLabel>Report Action</StepLabel>
          <StepContent>
            <div className="comment-blk" onClick={() => { this.setState({ addComment: true }) }}>{this.state.comment}</div>
            <div className="btn-cntr">
              <Button variant="contained" color="primary" onClick={() => { this.props.updateAction(reportId, 'a-003', uid, this.state.comment) }}>Done</Button>
            </div>
          </StepContent>
        </Step>
      </Stepper>
      {type === 2 ? <div style={{ margin: "1rem", textAlign: "center" }}>Thanks for responding to the situation!!!</div> : null}

      <Dialog fullScreen open={this.state.addComment} onClose={() => { this.setState({ addComment: false }) }}>
        <DialogContent>
          <DialogContentText>Write here</DialogContentText>
          <TextField
            multiline
            rowsMax="4"
            value={this.state.comment}
            onChange={this.handleCommentChange()}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { this.setState({ addComment: false }) }} color="primary">Cancel</Button>
          <Button onClick={this.handleSubmitComment} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
    }
    return null;
  }
}

export default OnIt;