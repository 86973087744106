import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Modal from '@material-ui/core/Modal';
import './actionCard.css';
import { fetchReportData, updateAction } from '../redux_actions';
import CancelIcon from '@material-ui/icons/Cancel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OnIt from './onIt';
import ReferToMgmt from './referToMgmt';
import ImageIcon from '@material-ui/icons/Image';
const imgWidth = window.innerWidth / 3;
const imgHeight = imgWidth;
const today = new Date();
let options = { year: 'numeric', month: 'short', day: 'numeric' };
class ActionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { report: {}, ImgZoom: false, actionPanel: 0 };
    this.toggleImgZoom = (cs) => { this.setState({ ImgZoom: !this.state.ImgZoom }) }
    this.shuffleActionPanel = (ev, val) => { this.setState({ actionPanel: val }) }
  }
  componentDidMount(){
    fetchReportData(this.props.match.params.reportId, (snap) => this.setState({ report: snap.val() }))
  }
  render() {
    let report = this.state.report;
    let signals = this.props.app.all_signals;
    let locations = this.props.app.all_locations;
    let isHindi = this.props.app.hindi;
    if (Object.keys(report).length) {
      let time = new Date(report.timestamp);
      let timeString;
      if (time.getDate() === today.getDate()) { timeString = time.toLocaleTimeString().replace(/:\d+ /, ' '); }
      else { timeString = time.toLocaleDateString("en-US", options) }
      return <div className="action-card-bg">
        <div className="action-card-details">
          {report.image ? <img alt="miss-img" onClick={this.toggleImgZoom} className="action-card-image" src={report.image} height={imgWidth} width={imgHeight} /> : <div style={{ height: imgWidth, width: imgHeight }} className="no-img-cntr"><ImageIcon fontSize="large" /></div>}
          {/* <img onClick={this.toggleImgZoom} className="action-card-image" src={report.image} height={imgWidth} width={imgHeight} /> */}
          <div className="action-card-text">
            <div className="dashboard-time">{timeString}</div>
            <div className="dashboard-location">{isHindi?locations[report.location]["title-hn"]:locations[report.location].title}</div>
            <div className="dashboard-title">{isHindi?signals[report.signal]["title-hn"]:signals[report.signal].title}</div>
            {report.userComment ? <div className="userComment">{report.userComment}</div> : null}
          </div>
        </div>
        <div className="action-card-actions">
          <Tabs value={this.state.actionPanel} onChange={this.shuffleActionPanel} aria-label="simple tabs example">
            <Tab label="My Action Map" id="tab-0" aria-controls="tab-0" />
            <Tab label="Refer to Mgmt" id="tab-1" aria-controls="tab-1" />
          </Tabs>
          {renderTabs(this.state.actionPanel, this.props, report)}
        </div>
        <Modal open={this.state.ImgZoom} BackdropComponent={"div"} BackdropProps={{ className: "background-root" }}>
          <div className="modal-container">
            <div style={{ position: "relative" }}>
              <CancelIcon onClick={this.toggleImgZoom} className="cancel-icon" />
              <img src={report.image} style={{ border: "4px solid #fff" }} />
            </div>
          </div>
        </Modal>
      </div>
    }
    return null;
  }
}

function renderTabs(id, props, report){
  switch (id) {
    case 0:
      return <OnIt {...props} report={report}/>
    case 1:
      return <ReferToMgmt {...props} report={report}/>
    default:
      return <OnIt {...props} report={report}/>
  }
}

const mapStateToProps = state => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ updateAction }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ActionCard);