import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Switch from '@material-ui/core/Switch';
import Drawer from '@material-ui/core/Drawer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import LanguageIcon from '@material-ui/icons/Language';
import CloseIcon from '@material-ui/icons/Close';
import ReportIcon from '@material-ui/icons/Report';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { signOut } from "../containers/auth/redux_actions";
import { setParams } from "../containers/application/redux_actions";
import "./header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { drawerOpen: false };
    this.toggleDrawer = () => { this.setState({ drawerOpen: !this.state.drawerOpen }) }
  }
  render() {
    return <AppBar color="primary" classes={{ colorPrimary: "header-color" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.5rem" }}>
        {/* <img alt="tata-logo" style={{height: 24, margin: "0.8rem"}} src="/tata_logo.png"/> */}
        <MenuIcon onClick={this.toggleDrawer} />
      </div>
      <Drawer
        classes={{ root: "drawer-root", paper: "drawer-paper" }}
        open={this.state.drawerOpen}
        onClose={this.toggleDrawer}>
        <div className="drawer-top">
          <CloseIcon onClick={this.toggleDrawer} className="close-btn" />
          <div className="profile-blk">
            <div style={{fontSize: 72}}>
            <AccountCircleIcon fontSize="inherit" color="secondary"/>
            </div>
            <div>{this.props.app.user?this.props.app.user.name:null}</div>
          </div>
          {/* <div className="drawer-option-cntr">
            <div style={{ display: "flex", alignItems: "center" }}>
              <LanguageIcon />&nbsp;English/हिंदी
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>A</span>
              <Switch
                checked={this.props.hindi}
                onChange={() => { this.props.setParams({ target: "hindi", value: !this.props.hindi }) }}
                value="hindi"
                color="default"
                inputProps={{ 'aria-label': 'default checkbox' }}
              />
              <span>अ</span>
            </div>
          </div> */}
          <div className="drawer-option-cntr">
            <div onClick={this.props.signOut} style={{ display: "flex", alignItems: "center" }}><ExitToAppIcon />&nbsp;Logout</div>
          </div>
        </div>
        <div className="drawer-bottom">
          <img alt="illumine-logo" height={48} src="https://s3.ap-south-1.amazonaws.com/illumine-logos/illuminelogo.svg" />
          <div className="copyright">COPYRIGHT 2019; ILLUMINE. All rights reserved.</div>
        </div>
      </Drawer>
    </AppBar>
  }
}

const mapStateToProps = state => ({ app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ signOut, setParams }, dispatch);
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(Header);