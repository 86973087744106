import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchNearMissReportData, updateAction, updateEnSafeId } from '../redux_actions';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import ImageIcon from '@material-ui/icons/Image';
import TextField from '@material-ui/core/TextField';
import "./nearMiss.css";
import Button from '@material-ui/core/Button';
const imgWidth = window.innerWidth / 3;
const imgHeight = imgWidth;
const today = new Date();
let options = { year: 'numeric', month: 'short', day: 'numeric' };

class NearMissAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ImgZoom: false, report: {} };
    this.toggleImgZoom = (cs) => { this.setState({ ImgZoom: !this.state.ImgZoom }) }
    this.handleSubmitComment = (el) => {
      updateEnSafeId(this.props.match.params.reportId, this.state.enSafeId);
    }
  }
  componentDidMount() {
    fetchNearMissReportData(this.props.match.params.reportId, (snap) => this.setState({ report: snap.val(), enSafeId:snap.val().enSafeId }))
  }
  render() {
    let isHindi = this.props.app.hindi||false;
    let report = this.state.report;
    let nearMisses = this.props.app.all_nearMisses;
    let locations = this.props.app.all_locations;
    let nearMiss = nearMisses[report.nearMiss] || {};
    let time = new Date(report.timestamp);
    let timeString;
    if (time.getDate() === today.getDate()) { timeString = time.toLocaleTimeString().replace(/:\d+ /, ' '); }
    else { timeString = time.toLocaleDateString("en-US", options) }
    if(Object.keys(report).length){return <div>
      <div className="action-card-details">
        {report.image ? <img alt="miss-img" onClick={this.toggleImgZoom} className="action-card-image" src={report.image} height={imgWidth} width={imgHeight} /> : <div style={{ height: imgWidth, width: imgHeight }} className="no-img-cntr"><ImageIcon fontSize="large" /></div>}
        <div className="action-card-text">
          <div className="date-time">{timeString}</div>
          <div className="dashboard-location">{isHindi?locations[report.location]["title-hn"]:locations[report.location].title}</div>
          <div className="dashboard-title">{isHindi?nearMiss["title-hn"]:nearMiss.title}</div>
          {report.userComment ? <div className="userComment">{report.userComment}</div> : null}
        </div>
      </div>
      <div style={{ padding: "1rem" }}>
        <TextField
          disabled={report.enSafeId?true:false}
          fullWidth
          id="enSafe-Id"
          label="enSafe Log Id"
          value={this.state.enSafeId || ""}
          margin="normal"
          variant="outlined"
          onChange={(ev)=>{this.setState({enSafeId: ev.target.value})}}
        />
        {!report.enSafeId?<div style={{display:"flex", justifyContent:"ceter", margin: "1rem 0rem"}}>
          <Button variant="contained" onClick={this.handleSubmitComment} color="primary">Submit</Button>
        </div>:null}
      </div>
      <Modal open={this.state.ImgZoom} BackdropComponent={"div"} BackdropProps={{ className: "background-root" }}>
        <div className="modal-container">
          <div style={{ position: "relative" }}>
            <CancelIcon onClick={this.toggleImgZoom} className="cancel-icon" />
            <img src={report.image} style={{ border: "4px solid #fff" }} />
          </div>
        </div>
      </Modal>
    </div>}
    return null;
  }
}

const mapStateToProps = state => ({ auth: state.auth, app: state.app });
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ updateAction }, dispatch);
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(NearMissAction);