import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {updateComment} from '../redux_actions';

class ReferToMgmt extends React.Component{
  constructor(props) {
    super(props);
    this.state = { step: 0, addComment: false, comment: '' };
    this.handleCommentChange = () => event => {
      this.setState({ ...this.state, "comment": event.target.value });
    };
    this.handleSubmitComment = (el) => {
      updateComment(this.props.match.params.reportId, this.state.comment);
      this.setState({ ...this.state, addComment: false })
    }
  }
  render(){
    // let uid = this.props.auth.user.uid;
    // let reportId = this.props.match.params.reportId;
    let report = this.props.report;
    return <div style={{padding:"1rem"}}>
      <div className="comment-blk" onClick={()=>{this.setState({addComment:true})}}>{report.comment}</div>
      <Button onClick={this.handleSubmitComment} color="primary">Submit</Button>
      <Dialog fullScreen open={this.state.addComment} onClose={()=>{this.setState({addComment:false})}}>
        {/* <DialogTitle id="form-dialog-title">Provide a comment</DialogTitle> */}
        <DialogContent>
          <DialogContentText>Write here</DialogContentText>
          <TextField
            multiline
            rowsMax="4"
            value={this.state.comment}
            onChange={this.handleCommentChange()}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({addComment:false})}} color="primary">Cancel</Button>
          <Button onClick={this.handleSubmitComment} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  }
}

export default ReferToMgmt;