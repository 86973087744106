const INITIAL_STATE = {
  hindi: false,
  reports: {},
  signals: {},
  nearMisses:{},
  all_locations: {},
  all_events: {},
  all_signals: {},
  all_actions:{},
  all_settings: {
    fprActions:{}
  },
  all_nearMisses:{},
  all_nearMissesReported:{}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "ALL_LOCATIONS":
      return {...state, all_locations: action.payload}
    case "ALL_EVENTS":
      return {...state, all_events: action.payload}
    case "ALL_SIGNALS":
      return {...state, all_signals: action.payload}
    case "ALL_SETTINGS":
      return {...state, all_settings: action.payload}
    case "ALL_ACTIONS":
      return {...state, all_actions: action.payload}
    case "ALL_NEARMISS":
      return {...state, all_nearMisses: action.payload}
    // case "UPDATE_NEARMISSREPORTS":
    //     return { ...state, all_nearMissesReported: {
    //       ...state.all_nearMissesReported, [action.payload.location]: action.payload.reports
    //     } };
    case "SET_HINDI":
      return {...state, hindi: action.payload}
    case "UPDATE_REPORTS":
      return { ...state, reports: {
        ...state.reports, [action.payload.location]: action.payload.reports
      } };
    case "UPDATE_SIGNALREPORTS":
      return { ...state, signals:{
        ...state.signals, [action.payload.reportId]: action.payload.report
      }};
    case "RESET_SIGNALREPORTS": return{ ...state, signals:{}}
    case "RESET_NEARMISSREPORTS": return{ ...state, nearMisses:{}}
    case "UPDATE_NEARMISSREPORTS":
      return { ...state, nearMisses:{
        ...state.nearMisses, [action.payload.reportId]: action.payload.report
      }}
    default:
      return state;
  }
};
