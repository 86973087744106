import React, {Component} from "react";
import { Link as RouterLink } from 'react-router-dom';
import {Button} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';

// let loginLink = props => <Link to="./auth/login" {...props} />
let loginLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} to="./auth/login" {...props} />
));
// let registerLink = props => <Link to="./auth/register" {...props} />
let registerLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} to="./auth/register" {...props} />
));
const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
});

let Public = class Public extends Component{
  render(){
    const { classes } = this.props;
    return <div className="pg-public">
      <p style={{margin:"1rem 0rem", fontWeight:"bold"}}>First Person Responder Interface</p>
      <p style={{marginBottom: "2rem"}}>Pilot for Tata Steel Safety App</p>
      <div>
        <Button variant="outlined" component={loginLink} className={classes.button}>Sign In</Button>
        <Button variant="outlined" component={registerLink} className={classes.button}>Register</Button>
      </div>
    </div>
  }
}

export default withStyles(styles)(Public)