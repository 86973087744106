import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";
import "firebase/database";

const config = {
  apiKey: "AIzaSyCNVNvp3jSZQuL6uZJSPoIjHe4PbZhslEg",
  authDomain: "safety-app-proto-illumine.firebaseapp.com",
  databaseURL: "https://safety-app-proto-illumine.firebaseio.com",
  projectId: "safety-app-proto-illumine",
  storageBucket: "safety-app-proto-illumine.appspot.com",
  messagingSenderId: "681565459786",
  appId: "1:681565459786:web:2d3fae1d6475ea803b5db3",
  measurementId: "G-BYVEQJDFWV"
};
let messaging = null;

firebase.initializeApp(config);
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey("BPLzqE0O8aoFbtB8oC6PZd8YkkgpWUKwuPmvpEL8qu6NTSoAYFOCW8kR7YFT41Af1VNrO5qDsPrcZXxcxoukEGo");
}

function seekPermissionForNotification(user) {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      messaging.getToken().then((currentToken) => {
        if (currentToken) {
          updateUser({ uid: user.uid, forUpdates: { InstanceIdToken: currentToken } });
        } else {
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // showToken('Error retrieving Instance ID token. ', err);
        // setTokenSentToServer(false);
      });
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

export const loginAttempt = ({ email, password, successCallBack, failureCallback }) => {
  return dispatch => {
    dispatch({ type: "LOGIN_ATTEMPT", payload: true });
    if (password === "" && email !== "") {
      let errMsg = {};
      errMsg.message = "Please enter a valid password";
      loginAttempFail(dispatch, errMsg, failureCallback);
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          loginAttempSuccess(dispatch, user, successCallBack);
        })
        .catch(err => {
          // console.log(err);
          let errMsg = getErrorMsg(err);
          loginAttempFail(dispatch, errMsg, failureCallback);
          setTimeout(() => {
            dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: " " });
          }, 3000);
        });
    }
  };
};

firebase
  .auth()
  .onAuthStateChanged(function (user) {
    if (user && messaging) {
      seekPermissionForNotification(user);
      watchTokenRefresh(user);
    } else {
      console.log("no user");
    }
  });

export const registerAttempt = ({ email, password, name, successCallBack, failureCallback }) => {
  return dispatch => {
    dispatch({ type: "REGISTER_ATTEMPT", payload: true });
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        loginAttempSuccess(dispatch, user, successCallBack);
      })
      .catch(err => {
        //let errMsg = getErrorMsg(err);
        loginAttempFail(dispatch, err, failureCallback);
      });
  };
};

export const setErrMsg = el => {
  return dispatch => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const resetPassword = email => {
  var auth = firebase.auth();
  var emailAddress = email;
  return dispatch => {
    if (email) {
      dispatch({ type: "PASSWORD_RESET_IN_PROGRESS", payload: true });
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {
          dispatch({ type: "RESET_PASSWORD_LINK_SENT", payload: true });
        })
        .catch(function (error) {
          let err = getErrorMsg(error);
          setErrorMsgInForgotPassword(dispatch, err.message);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
    }
  };
};

export const updateUserDetails = user => {
  return dispatch => {
    dispatch({ type: "UPDATE_USER_UID", payload: user.uid });
  };
};

export const resetLoginPage = () => {
  return dispatch => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};

export const resetForgotPasswordPage = () => {
  return dispatch => {
    dispatch({ type: "RESET_FORGOT_PASSWORD_PAGE" });
  };
};

export const registerNewUserPage = () => {
  return dispatch => {
    dispatch({ type: "RESET_REGISTER_NEW_USER_PAGE" });
  };
};

export const signOut = () => {
  let auth = firebase.auth();
  return dispatch => {
    auth
      .signOut()
      .then(function () {
        dispatch({ type: "LOGOUT_ATTEMPT" })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

/**Helper Functions**/
const loginAttempSuccess = (dispatch, user, successCallBack) => {
  dispatch({ type: "LOGIN_SUCCEEDED", payload: user });
  successCallBack();
};

const loginAttempFail = (dispatch, err, failureCallback, pos) => {
  dispatch({ type: "LOGIN_FAILED", payload: err.message });
  failureCallback();
};

function getErrorMsg(error) {
  let { code } = error;

  switch (code) {
    case "auth/invalid-email":
      error.message = "Please enter a valid email id";
      break;
    case "auth/user-not-found":
      error.message = "This email id is not registered. Please register.";
      break;
    case "auth/wrong-password":
      error.message = "Incorrect email or password. Please try again.";
      break;
    case "auth/email-already-in-use":
      error.message = "This email id is already registered. Please use a different email id";
      break;
    default:
      break;
  }

  return error;
}

function setErrorMsgInForgotPassword(dispatch, msg) {
  dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: msg });
  setTimeout(() => {
    dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: " " });
  }, 3000);
}

function updateUser({ uid, forUpdates }) {
  let updates = {};
  Object.keys(forUpdates).map(editId => {
    updates[`/user/${uid}/${editId}`] = forUpdates[editId];
  });
  firebase.database().ref().update(updates);
}

function watchTokenRefresh(user) {
  messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
      console.log(refreshedToken);
      updateUser({ uid: user.uid, forUpdates: { InstanceIdToken: refreshedToken } });
    }).catch((err) => {
      console.log('Unable to retrieve refreshed token ', err);
    });
  });
}
/**End of Helper Functions**/
