import React from "react";
import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import "./EventList.css"

const today = new Date();
let options = { year: 'numeric', month: 'short', day: 'numeric' };
class EventList extends React.Component {
  render() {
    let locations = this.props.data.all_locations;
    let signals = this.props.type=='signal'?this.props.data.all_signals:this.props.data.all_nearMisses;
    let fprActions = this.props.data.all_settings.fprActions;
    let isHindi = this.props.data.hindi || false;
    return Object.keys(this.props.reports).map(reportId => {
      let report = this.props.reports[reportId];
      console.log(report);
      if (report) {
        let time = new Date(report.timestamp);
        let lastAction = report["lastAction"] ? report.actions[report["lastAction"]] : null;
        let timeString;
        if (time.getDate() === today.getDate()) { timeString = time.toLocaleTimeString().replace(/:\d+ /, ' '); }
        else { timeString = time.toLocaleDateString("en-US", options) }
        let action_ = report.actions && report.actions["a-003"] ? "a-003" : (report.actions && report.actions["a-002"] ? "a-002" : (report.actions && report.actions["a-001"] ? "a-001" : "a-000"));
        let action = lastAction ? lastAction.type : action_;
        if (report && locations[report.location]) {
          let a = report.timestamp;
          return <div key={reportId} className="report-blk" style={{ order: (Date.now() - report.timestamp) }} onClick={() => { this.props.type=='signal'?this.props.history.push(`./report/${reportId}`):this.props.history.push(`./nearMiss/${reportId}`) }}>
            <div className="left-blk">
              <div>{this.props.type=='signal'?'Unsafe Signal':'Near Miss'}</div>
              <div className="dashboard-time">{timeString}</div>
              <div className="dashboard-location">{isHindi ? locations[report.location]["title-hn"] : locations[report.location].title}</div>
              <div className="dashboard-title">{isHindi ? signals[report.signal||report.nearMiss]["title-hn"] : signals[report.signal||report.nearMiss].title}</div>
              {report.userComment ? <div style={{ fontSize: "0.8rem" }}>{report.userComment}</div> : null}
            </div>
            <div className="right-blk">
              <div className={`indicator ${action}`}></div>
              <div className="dashboard-status">{fprActions[action] ? fprActions[action].title : "Reported"}</div>
            </div>
          </div>
        }
      }
      return null
    })
  }
}

const mapStateToProps = state => ({ data: state.app });

export default connect(
  mapStateToProps,
  null
)(EventList);