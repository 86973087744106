import React, {Component} from "react";
import {Switch, Route, Redirect} from "react-router-dom";

import ApplicationHome from "./channelDashboard";
import ActionCard from "./actionCard";
import NearMissAction from "./nearMiss";

let Application = class Application extends Component {
  render() {
    return <Switch>
      <Route path="/application/dashboard" component={ApplicationHome}/>
      <Route path="/application/nearMiss/:reportId" component={NearMissAction}/>
      <Route path="/application/report/:reportId" component={ActionCard}/>
      <Route path="/application" render={()=>(<Redirect to="/application/dashboard" />)} />
    </Switch>
  }
}

export default Application;